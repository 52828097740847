.outline {
  background-color: lavender;
	position: relative;
}

.outline > .line {
	position: absolute;
	white-space: pre;
  height: auto;
  width: auto;
}

.grid {
  display: grid;
	justify-content: space-around;
	grid-template-columns: auto auto auto;
}
