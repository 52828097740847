.n898 {
  line-height: 1em;
  font-size: 1em;
}

@media (min-width: 200px) {
  .n898 {
    height: calc(1em + (1000em / 2));
  }
}
@media (min-width: 300px) {
  .n898 {
    height: calc(1em + (1000em / 3));
  }
}
@media (min-width: 400px) {
  .n898 {
    height: calc(1em + (1000em / 4));
  }
}
@media (min-width: 500px) {
  .n898 {
    height: calc(1em + (1000em / 5));
  }
}
@media (min-width: 600px) {
  .n898 {
    height: calc(1em + (1000em / 6));
  }
}
@media (min-width: 700px) {
  .n898 {
    height: calc(1em + (1000em / 7));
  }
}
@media (min-width: 800px) {
  .n898 {
    height: calc(1em + (1000em / 8));
  }
}
@media (min-width: 900px) {
  .n898 {
    height: calc(1em + (1000em / 9));
  }
}
@media (min-width: 1000px) {
  .n898 {
    height: calc(1em + (1000em / 10));
  }
}

.flex_row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.flex_column {
  height: 100%;
  display: flex;  
  flex-wrap: wrap;
  flex-direction: column;
}

.padded_header {
  padding-top: 0.2em;
  padding-bottom: 0.8em;
}
